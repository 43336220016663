'use client';

import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { useTranslations, useLocale, useFormatter } from 'next-intl';
import classNames from 'classnames';
import qs from 'qs';

import {
  SearchLocationFields,
  SearchProps,
} from '@/contexts/SearchContext/types';

import Autocomplete from '@/components/form/Autocomplete';
import Checkbox from '@/components/form/Checkbox';
import Label from '@/components/form/Label';
import Icon from '@/components/ui/Icon';
import Button from '@/components/ui/Button';
import DateRangePicker, { Ranges } from '@/components/ui/DateRangePicker';

import Geocode from './Geocode';
import styles from './Search.module.scss';
import {
  applyTimezomeWithoutSeconds,
  formatDatePlaceholder,
  formatDateTime,
  getUserTimeZone,
} from '@/utils/date';
import { useSearch } from '@/contexts/SearchContext';
import { add } from 'date-fns';
import { sendSearchEvent } from '@/google-analytics-events';

export interface IataPlacesProps {
  Erros: any[];
  Grupos: {
    Grupo: string;
    Lista: {
      Iata: string;
      Valor: string;
    }[];
  }[];
}
export interface SearchComponentProps {
  color?: 'primary' | 'secondary';
  onFetch?: (searchParams: string) => void;
  loading?: boolean;
  initialData?: SearchProps;
}

type LocaleProps = {
  open: boolean;
  term: string;
  selected: {
    group: string;
    label: string;
    value: string;
  };
};

type GeolocationProps = {
  valid: boolean;
  latitude: string;
  longitude: string;
  country: string;
  placeName: string;
  city?: string;
  province?: string;
};

const Search = ({
  color = 'primary',
  onFetch,
  loading = false,
  initialData,
}: SearchComponentProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();
  const t = useTranslations('Search.component');
  const inputCheckboxRef = useRef<HTMLInputElement | null>(null);
  const inputGeoSearchPickupRef = useRef<HTMLInputElement | null>(null);
  const inputGeoSearchReturnRef = useRef<HTMLInputElement | null>(null);
  const userTimeZone = getUserTimeZone();
  const { removeAllFilters, googleMapsLoaded, loadGoogleMaps } = useSearch();
  const [isLoading, setIsLoading] = useState(false);

  const classes = classNames(
    'search-component',
    styles.search,
    styles[`search--${color}`],
  );
  const labelColor = color === 'primary' ? 'secondary' : 'primary';

  const airport = ['Aeroportos', 'Airport'];

  const pickupDateTimeQS = initialData?.pickup.datetime;
  const returnDateTimeQS = initialData?.return.datetime;

  const startDate = applyTimezomeWithoutSeconds(new Date(), userTimeZone, 1);
  const endDate = applyTimezomeWithoutSeconds(new Date(), userTimeZone, 2);

  const [geolocationData, setGeoLocationData] = useState<{
    pickupLocale: GeolocationProps;
    returnLocale: GeolocationProps;
  }>({
    pickupLocale: {
      latitude: '',
      longitude: '',
      country: '',
      valid: false,
      placeName: '',
    },
    returnLocale: {
      latitude: '',
      longitude: '',
      country: '',
      valid: false,
      placeName: '',
    },
  });

  const [searchData, setSearchData] = useState<{
    pickupLocale: LocaleProps;
    returnLocale: LocaleProps;
    places: IataPlacesProps;
    ranges: Ranges;
  }>({
    pickupLocale: {
      open: false,
      term: initialData?.pickup.city ?? '',
      selected: {
        group: initialData?.pickup.type ?? '',
        label: initialData?.pickup.city ?? '',
        value: initialData?.pickup.iata ?? '',
      },
    },
    returnLocale: {
      open: false,
      term: initialData?.return.city ?? '',
      selected: {
        group: initialData?.return.type ?? '',
        label: initialData?.return.city ?? '',
        value: initialData?.return.iata ?? '',
      },
    },
    places: {
      Erros: [],
      Grupos: [],
    },
    ranges: {
      start: pickupDateTimeQS
        ? new Date(pickupDateTimeQS)
        : new Date(startDate),
      end: returnDateTimeQS ? new Date(returnDateTimeQS) : new Date(endDate),
    },
  });

  const [samePlace, setSamePlace] = useState(true);
  const [geoSearch, setGeoSearch] = useState({ pick: false, ret: false });
  const [pickupPlaceName, setPickupPlaceName] = useState('');
  const [returnPlaceName, setReturnPlaceName] = useState('');

  const formatByLocale = (
    locale: LocaleProps,
    dateRange: Date | null | undefined,
    userTimeZone: string,
  ) => ({
    city: locale.selected.label,
    iata: locale.selected.value,
    type: airport.some((item) => item === locale.selected.group)
      ? 'Airport'
      : 'City',
    datetime: applyTimezomeWithoutSeconds(dateRange, userTimeZone),
  });

  const formatByGeolocation = (
    geolocation: GeolocationProps,
    dateRange: Date | null | undefined,
    userTimeZone: string,
  ) => ({
    latitude: geolocation.latitude,
    longitude: geolocation.longitude,
    iata: geolocation.country,
    city: `${geolocation.city?.toUpperCase()} / ${geolocation.province}, ${
      geolocation.country
    }`,
    type: 'Country',
    datetime: applyTimezomeWithoutSeconds(dateRange, userTimeZone),
  });

  function setParams(): SearchProps {
    return {
      pickup: geoSearch.pick
        ? (formatByGeolocation(
            geolocationData.pickupLocale,
            searchData.ranges.start,
            userTimeZone,
          ) as SearchLocationFields)
        : (formatByLocale(
            searchData.pickupLocale,
            searchData.ranges.start,
            userTimeZone,
          ) as SearchLocationFields),
      return: geoSearch.ret
        ? (formatByGeolocation(
            geolocationData.returnLocale,
            searchData.ranges.end,
            userTimeZone,
          ) as SearchLocationFields)
        : (formatByLocale(
            searchData.returnLocale,
            searchData.ranges.end,
            userTimeZone,
          ) as SearchLocationFields),
    };
  }

  const handleGoToSearch = async (params: SearchProps) => {
    setIsLoading(true);
    const baseUrl = pathname.includes('search')
      ? pathname
      : `/search/${searchData.pickupLocale.term.split(' ')[0]}`;

    // const params: SearchProps = {
    //   pickup: geoSearch.pick
    //     ? (formatByGeolocation(
    //       geolocationData.pickupLocale,
    //       searchData.ranges.start,
    //       userTimeZone,
    //     ) as SearchLocationFields)
    //     : (formatByLocale(
    //       searchData.pickupLocale,
    //       searchData.ranges.start,
    //       userTimeZone,
    //     ) as SearchLocationFields),
    //   return: geoSearch.ret
    //     ? (formatByGeolocation(
    //       geolocationData.returnLocale,
    //       searchData.ranges.end,
    //       userTimeZone,
    //     ) as SearchLocationFields)
    //     : (formatByLocale(
    //       searchData.returnLocale,
    //       searchData.ranges.end,
    //       userTimeZone,
    //     ) as SearchLocationFields),
    // };

    const qsParams = qs.stringify(params);

    setIsLoading(false);

    if (onFetch) {
      setIsLoading(false);
      removeAllFilters();
      const response = await onFetch(qsParams);
      console.log(response, 'response');
      if (history) history.replaceState(null, '', '?' + qsParams);
    } else {
      setIsLoading(true);
      router.push(baseUrl + '?' + qsParams);
    }
  };

  const clearSearchDataPlace = (place: string) => {
    setSearchData((prev) => ({
      ...prev,
      [place]: {
        open: false,
        term: '',
        selected: {
          group: '',
          label: '',
          value: '',
        },
      },
    }));
  };

  const clearGeoLocationPlace = (place: string) => {
    setGeoLocationData((prev) => ({
      ...prev,
      [place]: {
        latitude: '',
        longitude: '',
        country: '',
        valid: false,
        placeName: '',
      },
    }));
  };

  const handleSetGeoSearch = (
    place: string,
    input: React.RefObject<HTMLInputElement>,
    clear: string,
  ) => {
    setGeoSearch((prev) => ({
      ...prev,
      [place]: input?.current?.checked ?? false,
    }));

    if (clear === 'returnLocale' && samePlace) {
      setSamePlace(false);
      if (inputCheckboxRef.current) inputCheckboxRef.current.checked = false;
    }

    if (input?.current?.checked) {
      return clearSearchDataPlace(clear);
    }

    clearGeoLocationPlace(clear);
  };

  const handleFindPlaces = async (
    placeType: 'pickupLocale' | 'returnLocale',
    term: string,
  ) => {
    setSearchData((prev) => ({
      ...prev,
      [placeType]: { ...prev[placeType], term },
    }));

    if (term.length >= 3) {
      const placesSearch = await axios.get(`api/places?term=${term}`);

      if (
        !placesSearch ||
        !placesSearch.data ||
        placesSearch.data.Erros.length > 0
      ) {
        return;
      }

      setSearchData((prev) => ({
        ...prev,
        places: placesSearch.data,
        [placeType]: { ...prev[placeType], open: true },
      }));
    }
  };

  const handleSelectGeolocation = (
    placeType: 'pickupLocale' | 'returnLocale',
    value: GeolocationProps,
  ) => {
    if (!value) return;

    const copyPlace: { returnLocale: GeolocationProps } = {
      returnLocale: geolocationData.returnLocale,
    };

    if (placeType === 'pickupLocale' && samePlace) {
      copyPlace.returnLocale = value;
      setGeoSearch((prev) => ({
        ...prev,
        ret: inputGeoSearchPickupRef.current?.checked ?? false,
      }));
      clearSearchDataPlace('returnLocale');
    }

    setGeoLocationData((prev) => ({
      ...prev,
      ...copyPlace,
      [placeType]: value,
    }));
  };

  const handleSelectPlace = (
    placeType: 'pickupLocale' | 'returnLocale',
    value: string,
  ) => {
    if (!value) return;
    const values = JSON.parse(value);

    setSearchData((prev) => ({
      ...prev,
      [placeType]: {
        ...prev[placeType],
        open: false,
        term: values.item.label,
        selected: {
          group: values.group,
          label: values.item.label,
          value: values.item.value,
          code: values.item.code,
        },
      },
    }));

    if (placeType === 'pickupLocale' && inputCheckboxRef.current?.checked) {
      // handleSetSamePlace(true);
      setSearchData((prev) => ({
        ...prev,
        returnLocale: prev.pickupLocale,
      }));

      handleSetGeoSearch('ret', inputGeoSearchPickupRef, 'pickupLocale');
    }
  };

  const handleSetSamePlace = (yes: boolean) => {
    setSamePlace(!samePlace);
    handleSetGeoSearch('ret', inputGeoSearchPickupRef, 'returnLocale');
    if (geoSearch.pick && geolocationData.pickupLocale.valid) {
      return setGeoLocationData((prev) => ({
        ...prev,
        returnLocale: prev.pickupLocale,
      }));
    }

    if (!searchData.pickupLocale.selected.value) return;

    if (yes) {
      setSearchData((prev) => ({
        ...prev,
        returnLocale: prev.pickupLocale,
      }));

      return;
    }

    clearSearchDataPlace('returnLocale');
  };

  const handleChangePlaces = () => {
    const switchGeoLocation = () => {};

    if (inputCheckboxRef.current?.checked) return;

    if (
      (!searchData.pickupLocale.selected.value &&
        !geolocationData.pickupLocale.valid) ||
      (!searchData.returnLocale.selected.value &&
        !geolocationData.returnLocale.valid)
    )
      return;

    if (!geoSearch.pick && !geoSearch.ret) {
      return setSearchData((prev) => ({
        ...prev,
        pickupLocale: prev.returnLocale,
        returnLocale: prev.pickupLocale,
      }));
    }

    if (geoSearch.pick && geoSearch.ret) {
      setGeoLocationData((prev) => ({
        ...prev,
        pickupLocale: prev.returnLocale,
        returnLocale: prev.pickupLocale,
      }));
      return;
    }
    setSearchData((prev) => ({
      ...prev,
      pickupLocale: prev.returnLocale,
      returnLocale: prev.pickupLocale,
    }));
    setGeoLocationData((prev) => ({
      ...prev,
      pickupLocale: prev.returnLocale,
      returnLocale: prev.pickupLocale,
    }));

    if (geoSearch.pick) {
    }
    setGeoSearch((prev) => ({
      pick: !prev.pick,
      ret: !prev.ret,
    }));
  };

  const formatStartPlaceholder = formatDatePlaceholder(
    searchData.ranges.start,
    userTimeZone,
  );

  const formatEndPlaceholder = formatDatePlaceholder(
    searchData.ranges.end,
    userTimeZone,
  );

  const startPlaceholder = searchData.ranges.start
    ? formatStartPlaceholder
    : t('nav.period.placeholder');

  const endPlaceholder = searchData.ranges.end
    ? formatEndPlaceholder
    : t('nav.period.placeholder');

  const nextDay = (currentDate: Date | null | undefined) => {
    if (currentDate) {
      const nextDay = add(currentDate, { days: 1 });
      return nextDay;
    }
    return currentDate;
  };

  useEffect(() => {
    removeAllFilters();
    loadGoogleMaps();
  }, []);

  useEffect(() => {
    if (!inputCheckboxRef.current?.checked) {
      clearSearchDataPlace('returnLocale');
      clearGeoLocationPlace('returnLocale');
    }
  }, [samePlace]);

  const validFormData = (): boolean =>
    (!!searchData.pickupLocale.selected.value ||
      geolocationData.pickupLocale.valid) &&
    (!!searchData.returnLocale.selected.value ||
      geolocationData.returnLocale.valid);

  const checkSamePlace = () => {
    if (geoSearch.pick) {
      return (
        geolocationData.pickupLocale.placeName ===
        geolocationData.returnLocale.placeName
      );
    }

    return searchData.pickupLocale.term === searchData.returnLocale.term;
  };
  return (
    <div className={classes}>
      <div className={styles['searchLocations']}>
        <div className={styles['searchLocations--with-checkbox']}>
          {!geoSearch.pick ? (
            <Autocomplete
              id="pickup-autocomplete"
              label={t('nav.location.label')}
              labelProps={{ color: labelColor, weight: '400' }}
              placeholder={t('nav.location.placeholder')}
              iconStart="map-initial"
              elSize="large"
              open={searchData.pickupLocale.open}
              value={searchData.pickupLocale.term}
              onOpen={(val) => null}
              onSearch={(term: string) =>
                handleFindPlaces('pickupLocale', term)
              }
              onValueChange={(val) => handleSelectPlace('pickupLocale', val)}
              options={searchData.places?.Grupos?.map((item) => ({
                group: item.Grupo,
                items: item.Lista.map((i) => ({
                  label: i.Valor,
                  value: i.Iata,
                })),
              }))}
            />
          ) : (
            <Geocode
              label={t('nav.location.label')}
              labelProps={{ color: labelColor, weight: '400' }}
              placeholder={t('nav.location.address')}
              placeName={geolocationData.pickupLocale.placeName}
              onValueChange={(val: GeolocationProps) =>
                handleSelectGeolocation('pickupLocale', val)
              }
              isDisabled={false}
              googleMapsLoaded={googleMapsLoaded}
            />
          )}
          <Checkbox
            label={t('nav.location.geo-search')}
            id="geo-search-pickup"
            wrapperStyle={styles.labelFooter}
            labelProps={{ weight: '400', color: labelColor }}
            onChange={(e) =>
              handleSetGeoSearch(
                'pick',
                inputGeoSearchPickupRef,
                'pickupLocale',
              )
            }
            checked={geoSearch.pick}
            ref={inputGeoSearchPickupRef}
          />
        </div>
        <button
          type="button"
          onClick={handleChangePlaces}
          className={styles.arrowsSwitch}
        >
          <Icon name="arrows" />
        </button>
        <div className={styles['searchLocations--with-checkbox']}>
          <Checkbox
            label={t('nav.location.same-origin')}
            id="same-place"
            labelProps={{
              weight: '400',
              color: labelColor,
            }}
            onChange={(e) => handleSetSamePlace(e.target.checked)}
            defaultChecked={checkSamePlace()}
            ref={inputCheckboxRef}
          />
          {!geoSearch.ret ? (
            <Autocomplete
              id="return-autocomplete"
              placeholder={t('nav.location.placeholder')}
              iconStart="map-pin"
              elSize="large"
              samePlace={samePlace}
              open={searchData.returnLocale.open}
              value={searchData.returnLocale.term}
              disabled={
                searchData.pickupLocale.term === searchData.returnLocale.term &&
                !geolocationData.pickupLocale.valid
              }
              onOpen={(val) => null}
              onSearch={(term: string) =>
                handleFindPlaces('returnLocale', term)
              }
              onValueChange={(val) => handleSelectPlace('returnLocale', val)}
              options={searchData.places?.Grupos?.map((item) => ({
                group: item.Grupo,
                items: item.Lista.map((i) => ({
                  label: i.Valor,
                  value: i.Iata,
                })),
              }))}
            />
          ) : (
            <Geocode
              label=""
              labelProps={{ color: labelColor, weight: '400' }}
              placeholder={t('nav.location.address')}
              placeName={geolocationData.returnLocale.placeName}
              onValueChange={(val: GeolocationProps) =>
                handleSelectGeolocation('returnLocale', val)
              }
              isDisabled={inputCheckboxRef.current?.checked ?? false}
              googleMapsLoaded={googleMapsLoaded}
            />
          )}
          <Checkbox
            label={t('nav.location.geo-search')}
            id="geo-search-return"
            wrapperStyle={styles.labelFooter}
            labelProps={{
              weight: '400',
              color: labelColor,
            }}
            onChange={(e) =>
              handleSetGeoSearch('ret', inputGeoSearchReturnRef, 'returnLocale')
            }
            checked={geoSearch.ret}
            ref={inputGeoSearchReturnRef}
          />
        </div>
      </div>

      <div className={styles.searchDates}>
        <Label as="p" color={labelColor}>
          {t('nav.period.label')}
        </Label>
        <DateRangePicker
          startDatePlaceholder={startPlaceholder}
          endDatePlaceholder={endPlaceholder}
          onChangeStart={(date) => {
            setSearchData((prev) => ({
              ...prev,
              ranges: { start: date, end: nextDay(date) },
            }));
          }}
          onChangeEnd={(date) =>
            setSearchData((prev) => ({
              ...prev,
              ranges: { ...prev.ranges, end: date },
            }))
          }
          ranges={searchData.ranges}
          localeIntl={locale}
        />
      </div>

      <Button
        onClick={() => {
          const params = setParams();
          handleGoToSearch(params);
          sendSearchEvent(params, undefined);
        }}
        type="button"
        disabled={!validFormData()}
        loading={isLoading || loading}
        size="small"
        transform="initial"
        weight="700"
        style={{ border: 0, marginBottom: '30px' }}
      >
        {t('nav.submit-button')}
      </Button>
    </div>
  );
};

export default Search;
