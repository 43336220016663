import Link, { LinkProps } from 'next/link';
import classNames from 'classnames';

import styles from './Card.module.scss';

interface CardBaseProps {
  children: React.ReactNode;
  extraClasses?: string;
  href?: string;
  scroll?: boolean;
  onClick?: () => void;
}

export type CardProps =
  | ({ href?: null | undefined | '' } & CardBaseProps)
  | ({ href: string } & CardBaseProps &
      React.AnchorHTMLAttributes<HTMLAnchorElement | LinkProps>);

const Card = ({
  children,
  extraClasses,
  href,
  scroll,
  onClick,
  ...rest
}: CardProps) => {
  const classes = classNames(styles.card, extraClasses);

  if (href) {
    return (
      <Link href={href} scroll={scroll} className={classes} {...rest}>
        {children}
      </Link>
    );
  }

  return (
    <div className={classes} onClick={onClick}>
      {children}
    </div>
  );
};

export default Card;
