'use client';

import { Tokens } from '@/config/tokens';

export const clearCheckoutData = () => {
  const hasBooking = localStorage.getItem(Tokens.MOBILITY_BOOKING);
  if (!hasBooking) return;

  localStorage.removeItem(Tokens.MOBILITY_AUTH);
  localStorage.removeItem(Tokens.MOBILITY_TOKEN);
  localStorage.removeItem(Tokens.MOBILITY_SELECTED_CAR);
  localStorage.removeItem(Tokens.MOBILITY_BOOKING);
};
