import qs from 'qs';

import {
  FiltersProps,
  SearchLocationFields,
} from '@/contexts/SearchContext/types';
import { MobilityCarProps, MobilitySearchProps, Store } from '@/types/mobility';
import { localesTypes } from '@/config/locales';
export const transformToUniqueCarsList = (data: MobilitySearchProps) => {
  if (!data) return [];

  const list = data.Availabilities?.CarsGroups?.reduce(
    (acc: any, curr: any) => {
      const cars = curr.Cars.map((c: MobilityCarProps) => {
        return {
          ...c,
          CarGroup: c.CarCategory,
          KmDescription: c.Fee.find(
            (f) => f.Description.toLowerCase() === 'km livre',
          )?.Description,
        };
      });

      return [...acc, ...cars];
    },
    [],
  );

  return list;
};

export const transformToStoreList = (carList: any[]): Store[] => {
  if (!carList) return [];

  const list = carList.reduce((acc: any, car: any) => {
    car.PickupStores.map((store: any) => {
      acc[store.Code] = store;
    });
    return acc;
  }, {});

  return Object.values(list);
};

export const paginate = (data: any[], page: number, perPage = 6) => {
  const startIndex = (page - 1) * perPage;
  const endIndex = startIndex + perPage;
  return data.slice(startIndex, endIndex);
};

export const applyFilters = (
  data: MobilityCarProps[],
  filters: FiltersProps,
  currency: string,
) => {
  let results = data || [];

  if (filters.prices.value.min && filters.prices.value.max) {
    results = results.filter((item: MobilityCarProps) => {
      if (currency === 'BRL') {
        return (
          item.Amounts.TotalAmountEquivalent >= filters.prices.value.min &&
          item.Amounts.TotalAmountEquivalent <= filters.prices.value.max
        );
      } else {
        return (
          item.Amounts.TotalAmountPayment >= filters.prices.value.min &&
          item.Amounts.TotalAmountPayment <= filters.prices.value.max
        );
      }
    });
  }

  if (
    filters.payment_method.value &&
    filters.payment_method.value.length === 1
  ) {
    results = results.filter((item: MobilityCarProps) =>
      filters.payment_method.value?.includes(item.PaymentMethod),
    );
  }

  if (filters.categories.value && filters.categories.value.length > 0) {
    results = results.filter((item: MobilityCarProps) => {
      return item.CarGroup && filters.categories.value?.includes(item.CarGroup);
    });
  }

  if (filters.ar_conditional.value) {
    results = results.filter((item: MobilityCarProps) => item.AirConditioning);
  }

  if (filters.transmission.value && filters.transmission.value.length > 0) {
    results = results.filter((item: MobilityCarProps) =>
      filters.transmission.value?.includes(item.Streaming),
    );
  }

  if (filters.fuel.value && filters.fuel.value.length > 0) {
    results = results.filter((item: MobilityCarProps) =>
      filters.fuel.value?.includes(item.Fuel),
    );
  }

  if (filters.companies.value && filters.companies.value.length > 0) {
    results = results.filter((item: MobilityCarProps) =>
      filters.companies.value?.includes(item.CarRentalCompanyIATA),
    );
  }

  if (filters.passengers.value && filters.passengers.value.length > 0) {
    results = results.filter((item: MobilityCarProps) => {
      if (filters.passengers.value?.includes(8)) {
        return (
          filters.passengers.value?.includes(item.NumberOfPassengers) ||
          item.NumberOfPassengers >= 8
        );
      }

      return filters.passengers.value?.includes(item.NumberOfPassengers);
    });
  }

  if (filters.baggages.value && filters.baggages.value.length > 0) {
    results = results.filter((item: MobilityCarProps) =>
      filters.baggages.value?.includes(item.QuantityOfBaggages),
    );
  }

  // portas
  // cobertura - protecao

  if (filters.km.value && filters.km.value.length === 1) {
    const isFreeKm = Boolean(
      filters.km.value && filters.km.value.includes('free'),
    );
    const isLimitedKm = Boolean(
      filters.km.value && filters.km.value.includes('limited'),
    );

    if (isFreeKm) {
      results = results.filter((item: MobilityCarProps) => {
        return item.KmDescription === 'Km Livre';
      });
    }

    if (isLimitedKm) {
      results = results.filter((item: MobilityCarProps) => {
        return item.KmDescription !== 'Km Livre';
      });
    }
  }

  if (filters.tariff_type.value) {
    results = results.filter((item: MobilityCarProps) => item.CarWithFranchise);
  } else {
    results = results.filter(
      (item: MobilityCarProps) => !item.CarWithFranchise,
    );
  }

  // experiences

  return results;
};

export const buildSearchData = (searchString: string) => {
  const params = qs.parse(searchString);
  const pickupData = params.pickup as SearchLocationFields;
  const returnData = params.return as SearchLocationFields;

  const data = {
    pickup: {
      city: pickupData?.city || '',
      iata: pickupData?.iata || '',
      type: pickupData?.type || '',
      datetime: pickupData?.datetime || '',
      latitude: pickupData?.latitude || '',
      longitude: pickupData?.longitude || '',
    },
    return: {
      city: returnData?.city || '',
      iata: returnData?.iata || '',
      type: returnData?.type || '',
      datetime: returnData?.datetime || '',
      latitude: returnData?.latitude || '',
      longitude: returnData?.longitude || '',
    },
  };

  return data;
};
