'use client';

import { useFormatter } from 'next-intl';

import { useCurrency } from '@/contexts/CurrencyContenxt';

interface CurrencyProps {
  value: number;
  valueEn: number;
  style?: string;
}

const Currency = ({ value, valueEn, style }: CurrencyProps) => {
  const { currency } = useCurrency();
  const format = useFormatter();

  const currentValue = currency === 'BRL' ? value : valueEn;

  return (
    <span className={`${style}`}>
      {format.number(currentValue, {
        style: 'currency',
        currency,
      })}
    </span>
  );
};

export default Currency;
