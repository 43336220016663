'use client';

import { useTranslations } from 'next-intl';

import { MobilityCarProps } from '@/types/mobility';

import Currency from '@/components/translation/Currency';
import Button from '@/components/ui/Button';
import Card from '@/components/ui/Card';
import CarFeatures from '@/components/ui/CarFeatures';
import Image from 'next/image';
import { enhancedImageWithParameter } from '@/utils/url';
import styles from './CarCard.module.scss';

interface CarCardProps {
  showButton?: boolean;
  href?: string;
  onClick?: () => void;
  data: MobilityCarProps;
  valueToDisplay?: 'day' | 'total';
}

interface PriceProps extends Pick<CarCardProps, 'data' | 'valueToDisplay'> {
  t: any;
}

const Price = ({ t, data, valueToDisplay = 'day' }: PriceProps) => {
  if (valueToDisplay === 'day') {
    return (
      <>
        {t('from_price')}
        {'  '}
        <Currency
          value={data?.Amounts?.EstimatedDailyEquivalent}
          valueEn={data?.Amounts.DailyEstimated}
        />
        /{t('day')}
      </>
    );
  }
  return (
    <>
      Total:{' '}
      <Currency
        value={data?.Amounts?.TotalAmountEquivalent}
        valueEn={data?.Amounts.TotalAmountPayment}
      />
    </>
  );
};

const CarCard = ({
  showButton = false,
  href,
  data,
  valueToDisplay = 'day',
  onClick,
}: CarCardProps) => {
  const t = useTranslations('common');

  if (!data) return null;

  return (
    <Card
      onClick={onClick}
      href={href || undefined}
      scroll={false}
      extraClasses={`car-card ${styles.carCard}`}
    >
      <div className={styles.header}>
        <span />
        <Image
          src={`/img/companies/${data.CarRentalCompanyIATA}.png`}
          alt={`${data.CarRentalCompanyName} Logo`}
          width={96}
          height={72}
        />
        {/* <img
          src={`/img/companies/${data.CarRentalCompanyIATA}.png`}
          alt={`${data.CarRentalCompanyName} Logo`}
          width={96}
          height={72}
        /> */}
      </div>
      <div className={styles.img}>
        <Image
          src={enhancedImageWithParameter(data.Image)}
          width={300}
          height={186}
          alt={data.Model}
          sizes="auto"
          style={{
            objectFit: 'contain',
            objectPosition: 'top',
          }}
        />

        {/* 
          <img
            src={enhancedImageWithParameter(data.Image)}
            alt={data.Model}
            sizes="auto"
            style={{ objectFit: 'cover' }}
          /> */}
      </div>
      <div className={styles.details}>
        {data.CarGroup && <p>{data.CarGroup}</p>}
        <h5 className={styles['details__title']}>{data.Model}</h5>
        <h4>
          <Price data={data} valueToDisplay={valueToDisplay} t={t} />
        </h4>
        <p style={{ textAlign: 'right', margin: 0 }}>
          <small>{t('disclaimer_fees_apply')}</small>
        </p>
      </div>
      <CarFeatures
        baggages={data.QuantityOfBaggages}
        km={data.KmDescription}
        passegenrs={data.NumberOfPassengers}
        transmission={data.Streaming}
      />
      {showButton && (
        <Button color="primary" transform="uppercase" full>
          {t('see_similar')}
        </Button>
      )}
    </Card>
  );
};

export default CarCard;
