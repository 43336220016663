import { useEffect } from 'react';
import styles from './Popover.module.scss';
import { Options } from '../Autocomplete';
import { useTranslations } from 'next-intl';

export default function Popover({
  open,
  options,
  onSearch,
  onValueChange,
  onOpen,
  value,
}: {
  open: boolean;
  options: Options[];
  onSearch: (value: string) => void;
  onValueChange: (value: string) => void;
  onOpen: (value: boolean) => void;
  value: string | undefined;
}) {
  const t = useTranslations();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const popover = document.getElementById('popover');
      if (open && popover?.id !== (event.target as HTMLElement).id) {
        onOpen(false);
        onSearch('');
        document.removeEventListener('mousedown', handleClickOutside);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    open &&
    value &&
    value.length > 0 && (
      <div className={styles.popup}>
        {options.map((suggestion: any, index: any) => (
          <div key={index} className={styles['select__container']}>
            <span className={styles['select__item-label']}>
              {t(`common.${suggestion.group.toLowerCase()}`)}
            </span>
            {suggestion.items.map((item: any) => (
              <div
                key={item.value}
                className={styles['select__item']}
                id="popover"
                onClick={() => {
                  onValueChange(
                    JSON.stringify({ group: suggestion.group, item }),
                  );
                  onOpen(false);
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  );
}
