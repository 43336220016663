'use client';

import { sendGAEvent } from '@next/third-parties/google';
import {
  AdditionalItemsProps,
  FeeProps,
  MobilityCarProps,
} from './types/mobility';
import { FiltersProps, SearchProps } from './contexts/SearchContext/types';

//functions to auxiliate the GA event
const getCarModelGA = (cars: MobilityCarProps[]): CarModelGA[] => {
  return cars.map((car) => {
    return {
      item_id: '',
      item_name: car.Model,
      item_rental_company: car.CarRentalCompanyName,
      item_car_category: car.CarCategory,
      item_payment_badge: car.PaymentMethod,
      item_price: car.Amounts?.TotalAmountPayment,
      item_currency: car.Amounts?.Currency,
      item_passenger_capacity: car.NumberOfPassengers,
      item_mileage_type: car.KmDescription,
      item_luggage_capacity: car.QuantityOfBaggages,
      item_quantity: 1,
      item_transmission: car.GroupType,
    };
  });
};

//send the search event to GA
export const sendSearchEvent = (
  params: SearchProps | undefined,
  results:
    | {
        quantity: number;
        pages: number;
        data: MobilityCarProps[];
      }
    | undefined,
) => {
  if (!params) return;
  const cars = results?.data.map((car: MobilityCarProps) => car);

  sendGAEvent('event', 'search', {
    search_cars: {
      s_pickup_location: params?.pickup?.city,
      s_return_location: params?.return?.city,
      s_pickup_datetime: params?.pickup?.datetime,
      s_return_datetime: params?.return?.datetime,
      s_pickup_geolocation: params?.pickup?.latitude !== undefined,
      s_return_geolocation: params?.return?.latitude !== undefined,
      s_search_results: results?.quantity,
    },
    results: cars != undefined && cars.length > 0 ? getCarModelGA(cars) : [],
  });
};

//send the purchase event to GA
export const sendPurchaseEvent = (
  car?: MobilityCarProps,
  totalAmount?: number,
  transactionId?: string,
) => {
  if (!car || !totalAmount || !transactionId) return;

  sendGAEvent('event', 'purchase', {
    transaction_id: transactionId,
    currency: car?.Amounts?.Currency,
    value: totalAmount,
    items: [
      {
        item_name: car?.Model,
        affiliation: car?.CarRentalCompanyName,
        quantity: 1,
        price:
          car?.Amounts?.Currency === 'USD'
            ? car?.Amounts?.TotalAmountPayment
            : car?.Amounts?.TotalAmountEquivalent,
        current_exchange: car?.Amounts?.ExchangeUSD,
      },
    ],
  });
};

//send the request reservation checkout event to GA
export const sendRequestReservationCheckoutEvent = (
  car: MobilityCarProps,
  currentStep: number,
  additionalItems: AdditionalItemsProps[],
  fees: FeeProps[],
) => {
  sendGAEvent('event', 'request_reservation_checkout', {
    page_location: `step_${currentStep}`,
    selected_item: additionalItems,
    selected_item_fee: fees,
    begin_checkout: currentStep === 4,
    view_item: getCarModelGA([car]),
  });
};

//send the filters information event to GA
export const sendFiltersInformationEvent = (filters: FiltersProps) => {
  sendGAEvent('event', 'filters_information', {
    f_payment_method: filters?.payment_method.value,
    f_price_range: filters?.pricesMinMax.value,
    f_car_category: filters?.categories.value,
    f_transmission: filters?.transmission.value,
    f_fuel_type: filters?.fuel.value,
    f_rental_company: filters?.companies.value,
    f_air_conditioning: filters?.ar_conditional.value,
    f_insurance: filters?.protection.value,
    f_tariff_type: filters?.tariff_type.value,
    f_passenger_capacity: filters?.passengers.value,
    f_doors: filters?.doors.value,
    f_luggage_capacity: filters?.baggages.value,
    f_mileage_type: filters?.km.value,
    f_experience: filters?.experience.value,
  });
};

export const sendHomePageEvent = (data: HomePageGA) => {
  sendGAEvent('event', 'home_page', {
    page_location: data.page_location,
    page_type: data.page_type,
    page_title: data.page_title,
    u_id: data.u_id,
    u_country: data.u_country,
    login_status: data.login_status,
  });
};
