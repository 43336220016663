import { InputHTMLAttributes, forwardRef, ReactNode } from 'react';
import classNames from 'classnames/bind';

import Label, { LabelProps } from '@/components/form/Label';

import styles from './Checkbox.module.scss';

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'type'> {
  label?: string | ReactNode;
  labelProps?: Omit<LabelProps, 'children'>;
  size?: 'normal' | 'large';
  color?: 'primary' | 'secondary';
  error?: boolean;
  hide?: boolean;
  id: string;
  wrapperStyle?: any;
}

type Ref = HTMLInputElement;

const cx = classNames.bind(styles);

const Checkbox = forwardRef<Ref, CheckboxProps>(
  (
    {
      size = 'normal',
      color = 'primary',
      id,
      label,
      labelProps,
      error = false,
      hide,
      wrapperStyle,
      ...rest
    },
    ref,
  ) => {
    const classes = cx('checkbox', `checkbox--${size}`, `checkbox--${color}`, {
      'checkbox--error': error,
    });
    const labelPropsStyle = {
      size: labelProps?.size || size,
      color:
        labelProps?.color || (color === 'primary' ? 'secondary' : 'primary'),
      ...labelProps,
    };

    return (
      <label
        htmlFor={id}
        className={`${styles.checkboxWrapper} ${wrapperStyle}`}
      >
        <input id={id} type="checkbox" ref={ref} {...rest} />
        <span
          className={classes}
          style={{ display: hide ? 'none' : undefined }}
        />
        {label && (
          <Label as="span" {...labelPropsStyle}>
            {label}
          </Label>
        )}
      </label>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
