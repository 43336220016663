'use client';

// Import required dependencies and components
import { useEffect } from 'react';

import Input, { InputProps } from '@/components/form/Input';
import styles from './Autocomplete.module.scss';
import Popover from '../Popover';

export type Options = {
  group: string;
  items: { label: string; value: string }[];
};

export interface AutocompleteProps extends Omit<InputProps, 'value'> {
  open: boolean;
  onOpen: (open: boolean) => void;
  options: Options[];
  onSearch: (value: string) => void;
  value?: string;
  onValueChange: (value: string) => void;
  samePlace?: boolean;
}

// Clears the input field(s) when the backspace key is pressed.
// If `samePlace` is true and all inputs are empty, it resets the value.
const clearInput = (
  inputs: NodeListOf<HTMLInputElement>,
  onValueChange: (value: string) => void,
  samePlace?: boolean,
) => {
  if (inputs) {
    inputs.forEach((input: HTMLInputElement) => {
      input.addEventListener('keyup', (e: KeyboardEvent) => {
        if (e.key === 'Backspace' && samePlace) {
          if (input.value.length === 0) {
            inputs.forEach((input: HTMLInputElement) => {
              input.value = '';
            });

            onValueChange(JSON.stringify({ group: '', item: '' }));
          }
        }
      });
    });
  }
};

// Autocomplete component for displaying a searchable input field with dropdown options.
const Autocomplete = ({
  open,
  onOpen,
  options,
  onSearch,
  value,
  onValueChange,
  samePlace,
  ...rest
}: AutocompleteProps) => {
  useEffect(() => {
    const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      '[name="data-input"]',
    );
    clearInput(inputs, onValueChange, samePlace);
  }, []);

  return (
    <div className={styles.select}>
      <Input
        {...rest}
        value={value}
        type="text"
        name="data-input"
        onChange={(e) => onSearch(e.target.value)}
      />
      <Popover
        open={open}
        options={options}
        onSearch={onSearch}
        onOpen={onOpen}
        value={value}
        onValueChange={onValueChange}
      />
    </div>
  );
};

export default Autocomplete;
