'use client';

import * as Popover from '@radix-ui/react-popover';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import { useState } from 'react';

registerLocale('pt-br', ptBr);

import Icon from '@/components/ui/Icon';
import styles from './DateRangePicker.module.scss';
import { useTranslations } from 'next-intl';

export type Ranges = {
  start: ReactDatePickerProps['startDate'];
  end: ReactDatePickerProps['endDate'];
};

export interface DateRangePickerProps {
  startDatePlaceholder: string;
  endDatePlaceholder: string;
  localeIntl: string;
  onChangeStart: (date: ReactDatePickerProps['startDate']) => void;
  onChangeEnd: (date: ReactDatePickerProps['endDate']) => void;
  ranges: Ranges;
}

const DateRangePicker = ({
  startDatePlaceholder,
  endDatePlaceholder,
  localeIntl,
  onChangeStart,
  onChangeEnd,
  ranges,
}: DateRangePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const t = useTranslations('date_range_picker');

  const handleStartDateChange = (date: Date) => {
    setSelectedStartDate(date);
    onChangeStart(date);
  };

  const handleEndDateChange = (date: Date) => {
    onChangeEnd(date);
    // if (selectedStartDate && date) {
    //   setIsOpen(false);
    // }
  };

  return (
    <ul className={styles.dateRangePicker}>
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger asChild>
          <li>
            <div>
              <Icon name="calendar" style={{ transform: 'translateY(8%)' }} />
              <p>{startDatePlaceholder}</p>
              <Icon
                name="arrows-range"
                style={{ transform: 'translateY(8%)' }}
              />
            </div>
            <div>
              <span className={styles.divider} />
            </div>
            <div>
              <Icon name="calendar" style={{ transform: 'translateY(8%)' }} />
              <p>{endDatePlaceholder}</p>
              <Icon
                name="arrows-range"
                style={{ transform: 'translateY(8%)' }}
              />
            </div>
          </li>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className={styles.popoverContent} sideOffset={5}>
            <div className={styles.datePickerContainer}>
              <div className={styles.datePickerWrapper}>
                <h4>{t('start_date')}</h4>
                <DatePicker
                  locale={localeIntl}
                  selected={ranges.start}
                  onChange={handleStartDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption={t('time_caption')}
                  dateFormat="dd/MM/yyyy HH:mm"
                  minDate={
                    new Date(new Date().setDate(new Date().getDate() + 1))
                  }
                  inline
                />
              </div>
              <div className={styles.datePickerWrapper}>
                <h4>{t('end_date')}</h4>
                <DatePicker
                  locale={localeIntl}
                  selected={ranges.end}
                  startDate={ranges.start}
                  endDate={ranges.end}
                  selectsEnd
                  onChange={handleEndDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption={t('time_caption')}
                  dateFormat="dd/MM/yyyy HH:mm"
                  minDate={ranges.start}
                  inline
                />
              </div>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </ul>
  );
};

export default DateRangePicker;
