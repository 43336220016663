import Icon from '@/components/ui/Icon';

import styles from './CarFeatures.module.scss';
import { useTranslations } from 'next-intl';

interface CarProps {
  model: string;
  group: string;
  image_url: string;
  transmission_type: string;
  air_conditioning: boolean;
  quantity_of_passengers: number;
  quantity_of_baggages: number;
  free_km: boolean;
}

interface CarFeaturesProps {
  transmission?: string;
  passegenrs?: number;
  km?: string;
  baggages?: number;
  car?: CarProps;
}

const CarFeatures = ({
  transmission = 'manual',
  passegenrs = 5,
  km,
  baggages = 4,
}: CarFeaturesProps) => {
  const t = useTranslations('CarFeatures');

  const normalizeStrings = (text: string) => {
    return text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  };

  return (
    <ul className={`car-features ${styles.carFeatures}`}>
      <li>
        <span className={styles['carFeatures__icon-img']}>
          <Icon name="gearbox" isImage />
        </span>
        <p>{t(normalizeStrings(transmission))}</p>
      </li>
      {passegenrs > 0 && (
        <li>
          <Icon name="users" />
          <p>
            {passegenrs} {t('sits')}
          </p>
        </li>
      )}
      {km && (
        <li>
          <Icon name="odometer" />
          <p>{t(normalizeStrings(km))}</p>
        </li>
      )}
      {baggages > 0 && (
        <li>
          <Icon name="briefcase" />
          <p>
            {baggages} {baggages > 1 ? t('suitcases') : t('suitcase')}
          </p>
        </li>
      )}
    </ul>
  );
};

export default CarFeatures;
