'use client';

import { useTranslations } from 'next-intl';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';

import Input from '@/components/form/Input';
import Button from '@/components/ui/Button';

const addressFormSchema = z.object({
  address: z
    .string({ required_error: 'required_field' })
    .min(1, { message: 'required_field' }),
});

export type AddressFormDataProps = z.infer<typeof addressFormSchema>;
type GeolocationProps = {
  valid: boolean;
  latitude: string;
  longitude: string;
  country: string;
  placeName: string;
  city?: string;
  province?: string;
};
export interface GeocodeProps {
  onValueChange: (value: GeolocationProps) => void;
  label: string;
  placeholder: string;
  placeName: string;
  isDisabled: boolean;
  googleMapsLoaded: boolean;
  labelProps: any;
}

const Geocode = ({
  onValueChange,
  label,
  placeholder,
  placeName,
  isDisabled,
  googleMapsLoaded,
  labelProps,
}: GeocodeProps) => {
  const t = useTranslations('Geocode.form.fields');
  const inputRef = useRef<HTMLInputElement>(null);
  const mapRef = useRef<HTMLDivElement>(null); // Referência para o div do mapa
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const {
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<AddressFormDataProps>({
    resolver: zodResolver(addressFormSchema),
  });

  useEffect(() => {
    if (inputRef.current && placeName != '') {
      inputRef.current.value = placeName;
    }
  }, [placeName]);

  useEffect(() => {
    if (!googleMapsLoaded || !window.google) return;
    if (window.google && window.google.maps) {
      const initAutocomplete = () => {
        const newAutocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current!,
          { types: ['establishment', 'geocode'] },
        );

        newAutocomplete.addListener('place_changed', () => {
          const place = newAutocomplete.getPlace();
          if (place && place.geometry) {
            const location = place.geometry.location;
            const addressComponents = place.address_components;
            const cityComponent = addressComponents.find((component: any) =>
              component.types.includes('locality'),
            );
            const fallBackCity = addressComponents.find((component: any) =>
              component.types.includes('administrative_area_level_2'),
            );

            const provinceComponent = addressComponents.find((component: any) =>
              component.types.includes('administrative_area_level_1'),
            );

            const city = cityComponent
              ? cityComponent.long_name
              : fallBackCity
                ? fallBackCity.long_name
                : null;

            const province = provinceComponent
              ? provinceComponent.short_name
              : null;

            const countryComponent = addressComponents.find((component: any) =>
              component.types.includes('country'),
            );
            onValueChange({
              valid: true,
              latitude: location.lat(),
              longitude: location.lng(),
              country: countryComponent ? countryComponent.short_name : null,
              city: city,
              province: province,
              placeName: place.formatted_address,
            });
            setValue('address', place.formatted_address || '');
          }
        });

        setAutocomplete(newAutocomplete);
      };

      initAutocomplete();
    }
  }, [setValue, googleMapsLoaded]);

  const onSubmit = (data: AddressFormDataProps) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      { address: data.address },
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus,
      ) => {
        if (
          status === google.maps.GeocoderStatus.OK &&
          results &&
          results.length > 0
        ) {
          const location = results[0].geometry.location;
          const addressComponents = results[0].address_components;
          const countryComponent = addressComponents.find((component: any) =>
            component.types.includes('country'),
          );
          onValueChange({
            valid: true,
            latitude: location.lat(),
            longitude: location.lng(),
            country: countryComponent ? countryComponent.short_name : null,
            placeName: results[0].formatted_address,
          });
        } else {
          console.error('Erro ao obter a localização:', status);
        }
      },
    );
  };
  return (
    <div className="form__row--full">
      <Input
        elSize="large-alternate"
        iconStart="map-pin"
        label={label}
        id="address"
        ref={inputRef}
        errors={errors?.address?.message}
        placeholder={placeholder}
        disabled={isDisabled}
        labelProps={labelProps}
      />
    </div>
  );
};

export default Geocode;
