import { getLocale } from 'next-intl/server';
import moment from 'moment-timezone';

moment.tz.setDefault('America/Sao_Paulo');

export async function toLocaleString(
  value: string | null | undefined,
  locale: string | null = null,
) {
  if (locale === null) locale = await getLocale();

  if (!value) return '';

  return new Date(value).toLocaleString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function formatDateTime(dateTime: moment.MomentInput, timeZone: string) {
  return moment(dateTime).tz(timeZone).format('YYYY-MM-DD HH:mm');
}

export function getUserTimeZone() {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userTimeZone;
}

export function applyTimezomeWithoutSeconds(
  dateTime: moment.MomentInput,
  timeZone: string,
  daysToAdd: number = 0,
  daysToSubtract: number = 0,
) {
  let adjustedDateTime = moment(dateTime).tz(timeZone);

  if (daysToAdd !== 0) {
    adjustedDateTime = adjustedDateTime
      .add(daysToAdd, 'days')
      .hours(10)
      .minutes(0);
  }

  if (daysToSubtract !== 0) {
    adjustedDateTime = adjustedDateTime
      .subtract(daysToSubtract, 'days')
      .hours(10)
      .minutes(0);
  }

  const isoStringWithoutSeconds = adjustedDateTime.format(
    'YYYY-MM-DDTHH:mm:ss',
  );

  return isoStringWithoutSeconds;
}

export function formatDatePlaceholder(
  dateTime: string | undefined | Date | null,
  timeZone: string,
) {
  const adjustedDateTime = moment(dateTime).tz(timeZone);
  let formatString;

  if (timeZone !== 'America/Sao_Paulo') {
    formatString = 'DD/MM/YYYY, h:mm A';
  } else {
    formatString = 'DD/MM/YYYY, HH:mm';
  }

  const formattedDateTime = adjustedDateTime.format(formatString);
  return formattedDateTime;
}
