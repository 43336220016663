import styles from './SectionHeader.module.scss';

export interface SectionHeaderProps {
  children?: React.ReactNode;
  title: string;
  titleSize?: 'xlarge' | 'large' | 'normal';
  subtitle?: string;
  color?: 'black' | 'white';
}

const colors = {
  black: 'text--color-700',
  white: 'text--color-light-50',
};

const titleSizes = {
  xlarge: 'title--xlarge',
  large: 'title--large',
  normal: '',
};

const SectionHeader = ({
  title,
  subtitle,
  children,
  titleSize = 'xlarge',
  color = 'black',
}: SectionHeaderProps) => (
  <div className={`section-header ${styles.sectionHeader}`}>
    <div>
      <h2
        className={`title ${titleSizes[titleSize]} ${
          color === 'white' && 'title--color-light-50'
        } ${styles['sectionHeader__title']}`}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {subtitle && <p className={`text ${colors[color]}`}>{subtitle}</p>}
    </div>

    {children && children}
  </div>
);

export default SectionHeader;
